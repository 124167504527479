
<template>
  <div>
    <div class="m-b-5">
      <RadioGroup
        v-model="type"
        type="button"
        button-style="solid"
        class="text-center"
        style="width:100%"
        @on-change="handleChangeType"
      >
        <Radio
          v-for="item in typeArray"
          :key="item.value"
          :label="item.value"
          style="width:50%"
        >{{item.name}}</Radio>
      </RadioGroup>
    </div>
    <div>
      <div
        class="m-b-20"
        v-show="type==='inter_industry'"
      >
        <Table
          size="small"
          :columns="industryColumns"
          :data="industryStaData"
          :row-class-name="rowClassName"
          @on-row-click="handleClickRow"
        ></Table>
      </div>
      <div v-show="type==='inter_industry'">
        <h2 class="text-center p-b-10">站点收入TOP10</h2>
        <Table
          class="nonbackground"
          size="small"
          :columns="stationColumns"
          :data="stationStaData"
        ></Table>
      </div>
    </div>
    <div v-show="type==='user'">
      <div class="m-b-20">
        <Table
          size="small"
          :columns="industryColumns"
          :data="supllierStaData"
          :row-class-name="rowClassName"
          @on-row-click="handleClickUser"
        ></Table>
        <diV class="paging_style">
          <Page
            size="small"
            :total="industryStaData.length"
            :page-size="currentPageSize"
            :current="currentPageNumber"
            @on-change="handlePageChange"
          ></Page>
        </diV>
      </div>
      <div class="m-b-20">
        <h2 class="text-center p-b-10">行为概览</h2>
        <Table
          class="nonbackground"
          size="small"
          :columns="userBehaviorColumns"
          :data="userBehaviorStaData"
        ></Table>
      </div>
      <div class="m-b-20">
        <h2 class="text-center p-b-10">签约行业TOP5</h2>
        <Table
          class="nonbackground"
          size="small"
          :columns="industrySignColumns"
          :data="industrySignStaData"
        ></Table>
      </div>
      <div>
        <h2 class="text-center p-b-10">签约站点TOP5</h2>
        <Table
          class="nonbackground"
          size="small"
          :columns="stationSignColumns"
          :data="stationSignStaData"
        ></Table>
      </div>
    </div>
  </div>
</template>

<script>
import { listMicroAmountAndConversionRateGb, listMicroStationTop10, listMicroUserBehavior, listMicroSignAmountGbStation } from '@/api/dw/micro'
export default {
  data () {
    return {
      type: 'inter_industry',
      typeArray: [
        { value: 'inter_industry', name: '行业' },
        { value: 'user', name: '代理商' }
      ],
      query: {
        endDate: '',
        gbType: 'inter_industry',
        publisherId: this.$store.getters.token.userInfo.publisherId,
        startDate: '',
        userId: null
      },
      chooseTableId: null,
      chooseUserId: null,
      industryStaData: [],
      industryColumns: [
        {
          renderHeader: (h) => {
            return h('span', this.type === 'inter_industry' ? '行业' : '渠道')
          },
          align: 'left',
          key: 'name',
          ellipsis: true,
          tooltip: true
        },
        { title: '成交客户', align: 'center', key: 'actualNumber' },
        {
          title: '转化率',
          align: 'center',
          key: 'numberRate',
          render: (h, params) => {
            return h('span', `${params.row.numberRate}%`)
          }
        },
        { title: '签约额(元)', align: 'center', key: 'value' },
        {
          title: '占比',
          align: 'right',
          key: 'value',
          render: (h, params) => {
            return h('span', `${params.row.rate}%`)
          }
        }
      ],
      stationStaData: [],
      stationColumns: [
        { title: '站点', align: 'left', key: 'name', ellipsis: true, tooltip: true },
        { title: '成交客户', align: 'center', key: 'actualNumber' },
        { title: '签约额(元)', align: 'center', key: 'value' },
        {
          title: '占比',
          align: 'right',
          key: 'value',
          render: (h, params) => {
            return h('span', `${params.row.rate}%`)
          }
        }
      ],
      supllierStaData: [],
      currentPageSize: 5,
      currentPageNumber: 1,
      userBehaviorStaData: [],
      userBehaviorColumns: [
        { title: '类型', key: 'name', ellipsis: true, tooltip: true },
        { title: '数据', align: 'center', key: 'value' },
        { title: '排名', align: 'right', key: 'rank' }
      ],
      industrySignStaData: [], // 签约行业TOP5数据
      industrySignColumns: [],
      stationSignStaData: [], // 签约站点TOP5数据
      stationSignColumns: [
        { title: '站点', align: 'left', key: 'name', ellipsis: true, tooltip: true },
        { title: '成交客户', align: 'center', key: 'number' },
        { title: '签约额(元)', align: 'center', key: 'actualAmount' },
        {
          title: '占比',
          align: 'right',
          key: 'proportion',
          render: (h, params) => {
            return h('span', `${params.row.proportion}%`)
          }
        }
      ]
    }
  },
  computed: {
    dateRange () {
      return this.$store.state.situationWeb.dateRange
    }
  },
  methods: {
    rowClassName (row) {
      return row.id === (this.type === 'inter_industry' ? this.chooseTableId : this.chooseUserId) ? 'situation-table-choose-row' : 'situation-table-row'
    },
    handleChangeType () {
      this.query.gbType = this.type
      this.getIndustryStaData()
    },
    /**
     * 获取行业统计数据
     */
    getIndustryStaData () {
      this.query.userId = null
      listMicroAmountAndConversionRateGb(this.query).then(res => {
        if (res && !res.errcode) {
          this.industryStaData = res
          this.supllierStaData = []
          if (this.type === 'user' && this.industryStaData.length) {
            // 处理代理商统计数据
            this.supllierStaData = this.industryStaData.slice(0, this.currentPageSize)
          }
        } else {
          this.industryStaData = []
        }
      })
    },
    handleClickRow (params) {
      this.chooseTableId = (this.chooseTableId === params.id) ? null : params.id
      this.$store.commit('set_selected_industry_id', this.chooseTableId)

      this.getStationStaData()
    },
    /**
     * 获取站点TOP10统计数据
     */
    getStationStaData () {
      const query = {
        endDate: this.query.endDate,
        startDate: this.query.startDate,
        publisherId: this.query.publisherId,
        platformIndustryId: this.chooseTableId
      }
      listMicroStationTop10(query).then(res => {
        if (res && !res.errcode) {
          this.stationStaData = res
        } else {
          this.stationStaData = []
        }
      })
    },
    /**
     * 代理商数据统计分页操作
     */
    handlePageChange (curPage) {
      const index = (curPage - 1) * this.currentPageSize
      this.supllierStaData = this.industryStaData.slice(index, this.currentPageSize * curPage)
    },
    handleClickUser (params) {
      this.chooseUserId = (this.chooseUserId === params.id) ? null : params.id
      this.$store.commit('set_selected_user_id', this.chooseUserId)

      this.getUserBehaviorStaData()
      this.getIndustrySigningStaData()
      this.getSignStationStaData()
    },
    /**
     * 获取用户行为概览数据
     */
    getUserBehaviorStaData () {
      const query = {
        endDate: this.query.endDate,
        startDate: this.query.startDate,
        publisherId: this.query.publisherId,
        userId: this.chooseUserId
      }
      listMicroUserBehavior(query).then(res => {
        if (res && !res.errcode) {
          this.userBehaviorStaData = res
        } else {
          this.userBehaviorStaData = []
        }
      })
    },
    /**
     * 获取签约行业TOP5
     */
    getIndustrySigningStaData () {
      [...this.industrySignColumns] = this.industryColumns
      const newCol = {
        title: '行业',
        align: 'left',
        key: 'name',
        ellipsis: true,
        tooltip: true
      }
      this.industrySignColumns.splice(0, 1, newCol)

      this.query.userId = this.chooseUserId
      this.query.gbType = 'inter_industry'
      listMicroAmountAndConversionRateGb(this.query).then(res => {
        if (res && !res.errcode) {
          this.industrySignStaData = res.length ? res.slice(0, 5) : res
        } else {
          this.industrySignStaData = []
        }
      })
    },
    /**
     * 获取签约站点TOP5
     */
    getSignStationStaData () {
      const query = {
        endDate: this.query.endDate,
        startDate: this.query.startDate,
        publisherId: this.query.publisherId,
        userId: this.chooseUserId
      }
      listMicroSignAmountGbStation(query).then(res => {
        if (res && !res.errcode) {
          this.stationSignStaData = res
        } else {
          this.stationSignStaData = []
        }
      })
    }
  },
  watch: {
    dateRange: {
      deep: true,
      immediate: true,
      handler (val) {
        if (val && val.startDate && val.endDate) {
          this.query.startDate = val.startDate
          this.query.endDate = val.endDate

          // 行业
          this.getIndustryStaData()
          this.getStationStaData()

          // 代理商
          this.getUserBehaviorStaData()
          this.getIndustrySigningStaData()
          this.getSignStationStaData()
        }
      }
    }
  }
}
</script>
